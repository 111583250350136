import React from 'react';
import {
  Box,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink,
  Text,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

const BreadcrumbSeparator = () => (
  <Box as={FontAwesomeIcon} icon={faChevronRight} size="xs" color="gray.500" />
);

type BreadcrumbItem = {
  title: string;
  href?: string;
};

type BreadcrumbProps = {
  items: BreadcrumbItem[];
};

const Breadcrumb = ({ items }: BreadcrumbProps) => (
  <ChakraBreadcrumb separator={<BreadcrumbSeparator />} fontSize="sm">
    {items.map(({ href, title }) => {
      if (href) {
        return (
          <ChakraBreadcrumbItem key={title}>
            <BreadcrumbLink
              color="gray.500"
              _hover={{
                color: 'gray.900',
              }}
              href={href}
            >
              {title}
            </BreadcrumbLink>
          </ChakraBreadcrumbItem>
        );
      }

      return (
        <ChakraBreadcrumbItem key={title} isCurrentPage>
          <Text color="gray.500">{title}</Text>
        </ChakraBreadcrumbItem>
      );
    })}
  </ChakraBreadcrumb>
);

export default Breadcrumb;
